<template>
  <t-filter-collection
    v-bind="$props"
    :available-filters="computedFilters"
    :apply-label="$t('global.actions.apply_filter')"
  />
</template>
<script>
import useAvailableFilters from './useAvailableFilters';
import { TFilterCollection } from '~/components/Filters';

const parsedProps = { ...TFilterCollection.props };
delete parsedProps.availableFilters;

export default {
  name: 'organization-filter-collection',

  props: {
    ...parsedProps,
  },

  setup() {
    const { availableFilters } = useAvailableFilters();

    return {
      availableFilters,
    };
  },

  computed: {
    computedFilters() {
      return this.availableFilters();
    },
  },
};
</script>
