<template>
  <organizations-index :fields="fields" :filters="filters" :query="query" />
</template>

<script>
import Vue from 'vue';
import Organization from '~/models/Organization';
import OrganizationsIndex from '~/pages/shared/organizations/OrganizationsIndex';

export default Vue.extend({
  components: { OrganizationsIndex },

  middleware: 'tenantmember',

  data: () => ({
    query: () => new Organization(),

    filters: ['search', 'usage_type'],

    fields: ['name', 'usage_type', 'address.city', 'created_at', 'actions'],
  }),
});
</script>
