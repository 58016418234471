<template>
  <t-page>
    <template #header>
      <h3 slot="header">
        {{ $t('fields.organizations') }}
      </h3>
      <NuxtLink v-if="canCreate" :to="{ name: $RouteEnum.ORGANIZATIONS.CREATE }">
        <t-button>{{ $t('pages.organization.actions.create') }}</t-button>
      </NuxtLink>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import IndexTable from '~/pages/shared/organizations/partials/IndexTable';

export default Vue.extend({

  components: { IndexTable },
  props: {
    query: {
      type: Function,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
      default: () => [],
    },

    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    canCreate() {
      return this.$ability.can('create', 'Organization');
    },
  },
});
</script>
